<template>
  <div class="wrapper" :class="[{'nav-open': $sidebar.showSidebar}]">
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <UserMenu :title="user_name"></UserMenu>
      <!--MobileMenu nav-mobile-section-start="true"></MobileMenu-->

      <template slot="links">
        <sidebar-item
          :link="{name: 'Home', icon: 'home', path: '/home'}"
        ></sidebar-item>
        <sidebar-item
          class="hide-sidebar-item"
          :link="{name: 'Map', icon: 'map', path: '/map'}"
        ></sidebar-item>
        <sidebar-item
          v-if="amIAdmin && amIFromRMD"
          :link="{name: 'Plans', icon: 'event_note', path: '/plans'}"
        ></sidebar-item>
        <sidebar-item
          :link="{name: 'Planning', icon: 'library_books', path: '/planning'}"
        ></sidebar-item>
        <sidebar-item
          v-if="amIAdmin"
          :link="{name: 'Groups', icon: 'view_quilt', path: '/groups'}"
        ></sidebar-item>
        <sidebar-item
          v-if="amIAdmin"
          :link="{name: 'Users', icon: 'people', path: '/users'}"
        ></sidebar-item>
        <sidebar-item
          v-if="amIAdmin && amIFromRMD"
          :link="{name: 'Translate', icon: 'translate', path: '/translate'}"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'bridges_and_tunnels',
            icon: 'terrain'
          }"
        >
          <sidebar-item
            :link="{
              name: 'bt_table',
              icon: 'terrain',
              path: '/bridges-tunnels-tables',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'bt_map',
              icon: 'terrain',
              path: '/bridges-tunnels-map'
            }"
          ></sidebar-item>
        </sidebar-item>

        <!--sidebar-item
          :link="{
            name: 'Summary',
            icon: 'insert_chart_outlined',
            path: '/summary'
          }"
        >
        </sidebar-item-->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar v-if="$route.name.toLowerCase() !== 'map'"></top-navbar>

      <!--fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin-->

      <div :class="{content: !$route.meta.hideContent}" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="$route.meta.showFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import {mapState, mapGetters} from 'vuex'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
//import MobileMenu from './Extra/MobileMenu.vue'
//import FixedPlugin from '../../FixedPlugin.vue'
import UserMenu from './Extra/UserMenu.vue'
import {ZoomCenterTransition} from 'vue2-transitions'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    //MobileMenu,
    //FixedPlugin,
    UserMenu,
    ZoomCenterTransition
  },
  data() {
    return {
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'danger',
      sidebarBackgroundImage: './img/sidebar-2.jpg',
      sidebarMini: true,
      sidebarImg: true
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    }
  },
  mounted() {
    let docClasses = document.body.classList
    let isWindows = navigator.platform.startsWith('Win')
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar')
      initScrollbar('sidebar-wrapper')
      initScrollbar('main-panel')

      docClasses.add('perfect-scrollbar-on')
    } else {
      docClasses.add('perfect-scrollbar-off')
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    }
  },
  computed: {
    ...mapState({me: (state) => state.Login.me}),
    ...mapGetters(['amIAdmin', 'amIFromRMD']),
    user_name() {
      return `${this.me.first_name} ${this.me.last_name}`
    }
  }
}
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

@media (max-width: 992px) {
  .hide-sidebar-item {
    display: none;
  }
}
</style>
