var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[{'nav-open': _vm.$sidebar.showSidebar}]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('UserMenu',{attrs:{"title":_vm.user_name}}),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{name: 'Home', icon: 'home', path: '/home'}}}),_c('sidebar-item',{staticClass:"hide-sidebar-item",attrs:{"link":{name: 'Map', icon: 'map', path: '/map'}}}),(_vm.amIAdmin && _vm.amIFromRMD)?_c('sidebar-item',{attrs:{"link":{name: 'Plans', icon: 'event_note', path: '/plans'}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{name: 'Planning', icon: 'library_books', path: '/planning'}}}),(_vm.amIAdmin)?_c('sidebar-item',{attrs:{"link":{name: 'Groups', icon: 'view_quilt', path: '/groups'}}}):_vm._e(),(_vm.amIAdmin)?_c('sidebar-item',{attrs:{"link":{name: 'Users', icon: 'people', path: '/users'}}}):_vm._e(),(_vm.amIAdmin && _vm.amIFromRMD)?_c('sidebar-item',{attrs:{"link":{name: 'Translate', icon: 'translate', path: '/translate'}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'bridges_and_tunnels',
          icon: 'terrain'
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'bt_table',
            icon: 'terrain',
            path: '/bridges-tunnels-tables',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'bt_map',
            icon: 'terrain',
            path: '/bridges-tunnels-map'
          }}})],1)],1)],2),_c('div',{staticClass:"main-panel"},[(_vm.$route.name.toLowerCase() !== 'map')?_c('top-navbar'):_vm._e(),_c('div',{class:{content: !_vm.$route.meta.hideContent},on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(_vm.$route.meta.showFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }